import React from 'react';
// import { Header as HeaderComponent } from '../../components/header';
// import { NavMenu } from '../../components/NavMenu';

function AppHeaderLayout() {
  /* eslint-disable react/jsx-fragments */
  return (
    <React.Fragment>
      {/* <HeaderComponent /> */}
      {/* <NavMenu /> */}
    </React.Fragment>
  );
  /* eslint-enable */
}

export default AppHeaderLayout;
