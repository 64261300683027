import React, { Component } from 'react';
import { HeadHelmet } from '../../utils';
import {
  BlogListItem,
} from '../../components';
import { backendURL } from '../../utils/env';
// import styles from './style.module.scss';

export default class BlogPage extends Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
    };
  }

  componentDidMount() {
    fetch(`${backendURL}/api/posts`)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            items: result.response.items,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        },
      );
  }

  render() {
    const { error, isLoaded, items } = this.state;
    /* eslint-disable react/jsx-fragments, react/jsx-filename-extension */
    if (error) {
      return (
        <div>
          {`Error: ${error.message}`}
        </div>
      );
    } else if (!isLoaded) { // eslint-disable-line
      return <div>Loading...</div>;
    } else {
      return (
        <React.Fragment>
          <HeadHelmet
            title="Blog"
            description="Мой личный блог, где я рассказываю обо всем"
            keywords="блог, личный блок Владислава, интересные ситуации, разработка, разобранные кейсы
            , разработка блога, разработка проектов"
          />
          <div className="container">
            {items.map((item) => (
              <div key={item.id}>
                <BlogListItem
                  postTitle={item.title}
                  postDescription={item.description}
                  postedDate={new Date(item.createdAt).toLocaleString()}
                  aliace={item.aliace}
                />
              </div>
            ))}
          </div>
        </React.Fragment>
      );
    }
    /* eslint-enable */
  }
}
