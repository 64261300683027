import React from 'react';

// import { Footer as FooterComponent } from '../../components/footer';

function AppFooterLayout() {
  /* eslint-disable react/jsx-fragments */
  return (
    <React.Fragment>
      {/* <FooterComponent /> */}
    </React.Fragment>
  );
  /* eslint-enable */
}

export default AppFooterLayout;
