import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter, Switch, Route,
} from 'react-router-dom';
import { AppLayout } from './layouts';
import {
  ArticlePage,
  BlogPage,
  HomePage,
} from './pages';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  // eslint-disable-next-line react/jsx-filename-extension
  <BrowserRouter>
    <AppLayout>
      <Switch>
        {/* <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} /> TODO:способ для удаления слеша в конце */}
        <Route exact path="/" component={HomePage} />
        <Route path="/blog/:aliace" component={ArticlePage} />
        <Route path="/blog" component={BlogPage} />
      </Switch>
    </AppLayout>
  </BrowserRouter>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
