import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import renderHTML from 'react-render-html';
import { HeadHelmet } from '../../utils';
import { backendURL } from '../../utils/env';
import styles from './style.module.scss';

export class ArticlePage extends Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
      error: undefined,
      isLoaded: false,
      item: {},
      httpStatus: 200,
    };
  }

  componentDidMount() {
    // eslint-disable-next-line react/destructuring-assignment,react/prop-types
    fetch(`${backendURL}/api/post/${this.props.match.params.aliace}`) // TODO: вынести в отдельную функцию работы с бекендом
      .then((res) => {
        if (res.status !== 200) {
          this.setState({
            httpStatus: res.status,
          });
        }
        res.json()
          .then((result) => {
            this.setState({
              isLoaded: true,
              item: result.response,
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error,
            });
          });
      });
    // .then((res) => res.json())
  }

  render() {
    const {
      error,
      isLoaded,
      item,
      httpStatus,
    } = this.state;
    /* eslint-disable react/jsx-fragments, react/jsx-filename-extension */
    if (error || httpStatus !== 200) {
      if (httpStatus !== 200) {
        if (httpStatus === 404) { // TODO: сделать 404 страницу
          return (
            <React.Fragment>
              <HeadHelmet
                title="Запрошенной статьи не существует"
                description="Запрошенной статьи не существует"
              />
              <div>
                {`Error: ${error}`}
                {`Error - status: ${httpStatus}`}
              </div>
            </React.Fragment>
          );
        }
        return (
          <React.Fragment>
            <HeadHelmet
              title="Что делать то?"
              description="Запрошенной статьи не существует"
            />
            <div>
              {`Error: ${error}`}
              {`Error - status: ${httpStatus}`}
            </div>
          </React.Fragment>
        );
      }
      return (
        <div>
          {`Error: ${error}`}
          {`Error - status: ${httpStatus}`}
        </div>
      );
    } else if (!isLoaded) { // eslint-disable-line
      return <div>Loading...</div>;
    } else {
      return (
        <React.Fragment>
          <HeadHelmet
            title={item.title}
            description={item.description}
          />
          <div className="container">
            <article className={styles.articleStyle}>
              <section className={styles.sectionHeadLine}>
                <div className={styles.headerArticleLine}>
                  <span>Pisarev Vladislav</span>
                  <span>
                    <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      {/* eslint-disable-next-line max-len */}
                      <path d="M12 2a10 10 0 1010 10A10 10 0 0012 2zm0 18.5a8.5 8.5 0 118.5-8.5 8.51 8.51 0 01-8.5 8.5zm.42-14.21h-1.5V13h5.75v-1.5h-4.25z" />
                    </svg>
                    {` ${new Date(item.createdAt).toLocaleString()}`}
                  </span>
                </div>
                <h1>{`${item.title}`}</h1>
                <p>{`${item.description}`}</p>
              </section>
              <section className={styles.articleContent}>
                <div className={styles.contentInner}>
                  {renderHTML(item.articleText)}
                </div>
              </section>
            </article>
          </div>
        </React.Fragment>
      );
    }
    /* eslint-enable */
  }
}

export default withRouter(ArticlePage);
