require('dotenv').config();

export const siteTitle = 'Pisarev Vladislav';

export const contactEmail = process.env.REACT_APP_CONTACTS_EMAIL;

/**
 * URL address of backend - API URL
 */
export const backendURL = process.env.REACT_APP_BACKEND_URL;
