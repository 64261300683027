import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { AppHeaderLayout } from './Header';
import { AppFooterLayout } from './Footer';

import './style.css';
// import 'bootstrap/dist/css/bootstrap.min.css';

const propTypes = {
  children: PropTypes.node.isRequired,
};

// eslint-disable-next-line react/prefer-stateless-function
export default class AppMainLayout extends Component {
  render() {
    const {
      children,
    } = this.props;

    /* eslint-disable react/jsx-fragments */
    return (
      <React.Fragment>
        <AppHeaderLayout />
        {children}
        <AppFooterLayout />
      </React.Fragment>
    );
    /* eslint-enable */
  }
}

AppMainLayout.propTypes = propTypes;
