import React, { Component } from 'react';
import { HeadHelmet } from '../../utils';
import logo from './assets/new_logo.svg';
import styles from './styles/styles.module.scss';
import { contactEmail } from '../../utils/env';

// eslint-disable-next-line react/prefer-stateless-function
export default class HomePage extends Component {
  render() {
    /* eslint-disable react/jsx-fragments, react/jsx-filename-extension */
    return (
      <React.Fragment>
        <HeadHelmet
          title="Home"
          description="Personal website"
        />
        <div className={styles.mainHome}>
          <img src={logo} className={styles.logoStyle} alt="Pisarev Vlad logo" />
          <div className={styles.divTextTest}>
            <p className={styles.textStyle}>
              For communicate with me you can use next resources:
            </p>
            <p>
              <a
                className={styles.specifiedLink}
                href={`mailto:${contactEmail}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                My Email -
                {contactEmail}
              </a>
            </p>
            <p>
              <a
                className={styles.specifiedLink}
                href="https://t.me/pisarevvlad"
                target="_blank"
                rel="noopener noreferrer"
              >
                My Telegram - @pisarevvlad
              </a>
            </p>
          </div>
        </div>
      </React.Fragment>
    );
    /* eslint-enable */
  }
}
