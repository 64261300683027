import { siteTitle } from './env';

// eslint-disable-next-line import/no-cycle
export {
  default as HeadHelmet,
} from './HeadPageData';

/**
 * Return site title
 * @param {?string} title
 * @returns {string}
 */
export const getSiteTitle = (title) => {
  return `${title ? `${title} | ${getSiteName}` : `${getSiteName}`}`;
};

/**
 * Const with site name
 * @type {string}
 */
export const getSiteName = siteTitle;
