import React, { Component } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  postTitle: PropTypes.string.isRequired,
  author: PropTypes.string,
  postedDate: PropTypes.string.isRequired,
  postDescription: PropTypes.string.isRequired,
  tag: PropTypes.string,
  aliace: PropTypes.string.isRequired,
};

const defaultProps = {
  author: 'Pisarev Vladislav',
  tag: undefined,
};

// eslint-disable-next-line react/prefer-stateless-function
export default class BlogItem extends Component {
  render() {
    const {
      postTitle, author, postedDate, postDescription, tag, aliace,
    } = this.props;
    return (
      <div className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
        <div className="col p-4 d-flex flex-column position-static">
          {tag && (
          <strong className="d-inline-block mb-2 text-primary">{`${tag}`}</strong>
          )}
          <h3 className="mb-0">{postTitle}</h3>
          <div className="mb-1 text-muted">{`${postedDate} - ${author}`}</div>
          <p className="card-text mb-auto">{postDescription}</p>
          <a href={`/blog/${aliace}`} className="stretched-link">Читать полностью...</a>
        </div>
        <div className="col-auto d-none d-lg-block">
          <svg
            className="bd-placeholder-img"
            width="200"
            height="250"
            xmlns="http://www.w3.org/2000/svg"
            role="img"
            aria-label="Placeholder: Thumbnail"
            preserveAspectRatio="xMidYMid slice"
            focusable="false"
          >
            <title>Placeholder</title>
            <rect width="100%" height="100%" fill="#55595c" />
            <text x="50%" y="50%" fill="#eceeef" dy=".3em">Thumbnail</text>
          </svg>

        </div>
      </div>
    );
  }
}

BlogItem.propTypes = propTypes;
BlogItem.defaultProps = defaultProps;
